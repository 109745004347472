import { useState } from "react";
import { isValidName, isValidEmail } from '../helper/FormHelper'
import ReactGA from 'react-ga4';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';

const EarlyAccessForm = (props) => {
  // UI Loading
  const [loading, setLoading] = useState(false)

  // Email Sending
  const sendEmail = async (e) => {
    e.preventDefault();
    setValidated(true)
    
    var firstName = formData.firstName
    var lastName = formData.lastName
    var email = formData.email
    // var message = formData.message
    // || message.length <= 0 // <-- remember to re-add if necessary
    if (!isValidName(firstName) || !isValidName(lastName) || !isValidEmail(email)) {
      toast.error('Invalid form submission. Try again!', {duration: 2000})
      return;
    }
  
    setLoading(true)

    try {
      await axios.post('https://api.portalcareerdiscovery.com/early-access-form', formData)
      // await axios.post('http://localhost:5000/early-access-form', formData)
      ReactGA.event({
        category: 'form',
        action: 'Button Press',
        label: 'connected-email-success'
      }) 
      setLoading(false)
      setTimeout(() => {
        setValidated(false)
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        }) 
        
        toast("You will receive an email in your inbox shortly.", {duration: 2500});
        toast.success('Submission successful!', {duration: 2500})

        if (props.submitprocess != null)
          props.submitprocess() 
      }, 100)
    } catch(e) {
      ReactGA.event({
        category: 'form',
        action: 'Button Press',
        label: 'connected-email-fail'
      }) 
      setLoading(false)
      setTimeout(() => {
        toast.error('Uh oh! Something went wrong: ' + e.response, {duration: 2000})
      }, 100)
    }
  }
  
  // Form Validation
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
  });

  // Form Update
  const onFieldChange = (event) => {
      const { name, value } = event.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };
  
  return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExit={() => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
          })
          setValidated(false)
        }}
    >
    
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>Early Access Form</h1>
          <p>Fill out this form to receive early access instructions in your email inbox.</p> 
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <Form validated={validated}>
          <Row className="mb-3">
            <Form.Group
              as={Col}
            >
              <Form.Label> First Name <span className="asterisk">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="firstName"
                value={formData.firstName}
                placeholder="John"
                onChange={onFieldChange}
                isInvalid={
                  validated && !/^[a-zA-Z0-9]+$/.test(formData.firstName) && formData.firstName === ""
                }
              />
            </Form.Group>
            <Form.Group
              as={Col}
            >
              <Form.Label> Last Name <span className="asterisk">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="lastName"
                value={formData.lastName}
                placeholder="Doe"
                onChange={onFieldChange}
                isInvalid={
                  validated && !/^[a-zA-Z0-9]+$/.test(formData.lastName) && formData.lastName === ""
                }
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address <span className="asterisk">*</span></Form.Label>
              <Form.Control 
                required
                name="email"
                type="email" 
                placeholder="Enter email"
                value={formData.email}
                onChange={onFieldChange}
                isInvalid={
                  validated && !/^\S+@\S+\.\S+$/.test(formData.email)
                } 
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          {/*
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Why do you want early access to Portal? <span className="asterisk">*</span></Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              required
              placeholder='I would like Early Access because...'  
              name="message"
              value={formData.message}
              onChange={onFieldChange}
              isInvalid={
                validated && formData.message.length > 0
              } 
            />
          </Form.Group>
          */}
        </Form>
        
        <span className="asterisk">*</span> Required
      </Modal.Body>
      <Modal.Footer>
      {loading ? 
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> 
          :
          <Button type="submit" onClick={sendEmail}>Submit</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}
export default EarlyAccessForm