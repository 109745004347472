// Image
import smallPoint from '../../images/Guy_Pointing_Small.png'
// Hooks
import useWindowDimensions from "../../dimensions/windowDimensions"
// Components
import { Stack } from 'react-bootstrap'
import DarkDivider from "../UI/DarkDivider"
import CheckmarkBullet from "../UI/CheckmarkBullet"

const CareerCenter = ({ contactUsClick }) => {
    const { width, isMobile } = useWindowDimensions()
    return (
        <div style={{ paddingTop: 50 }}>
            <div 
                style={
                    isMobile ? 
                    { margin:'auto', display:'block' } : 
                    { paddingBottom: 20, maxHeight: '100vh' }
                }
            >
            { isMobile ?
                <h2>
                    Help students never feel lost about career choice.
                </h2>
                :
                <h2>
                    Help students never feel lost <br></br>
                    about career choice.
                </h2>
            }
            </div>
            <div 
            style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row'
            }}
            className='careerCenterContent'
            >
            <div
                style= {{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{display: 'block', margin: 'auto'}} className='careerCenterLeftColumn'>
                    { isMobile ?
                        <p>
                            Students are stressed enough. Provide them a resource that makes choosing a career stress-free and, dare we say, fun.
                        </p> : 
                        width < 1269 ?
                        <p>
                            Students are stressed enough. Provide them <br></br> 
                            a resource that makes choosing a career <br></br>
                            stress-free and, dare we say, fun.
                        </p> :
                        <p>
                            Students are stressed enough. Provide them a resource that <br></br> 
                            makes choosing a career stress-free and, dare we say, fun.
                        </p>
                        
                    }
                    <DarkDivider/>
                    <CheckmarkBullet text="Remove stress from the career search process"/>
                    <DarkDivider/>
                    <CheckmarkBullet text="Bring more students into the career center"/>
                    <DarkDivider/>
                    <CheckmarkBullet text="Help students envision possibilities for their future"/>
                    <DarkDivider/>
                    <button 
                        style={{ marginTop: 20, marginBottom: 50 }}
                        className={ isMobile ? 'gea-btn' : 'careerBtn' } 
                        onClick={ contactUsClick }
                    > 
                        Contact Us
                    </button>
                </div>
            </div>
            <Stack direction={ isMobile ? 'horizontal' : 'vertical' }>
                <div 
                style={{ 
                    display:'block', 
                    marginLeft:'auto', 
                    marginRight:'auto', 
                    marginTop: !isMobile ? 'auto' : 0,
                    paddingTop: 15
                }}
                >
                <img src={smallPoint} alt='point' className='point' />
                </div>
            </Stack>
            </div>
        </div>
    )
}

export default CareerCenter