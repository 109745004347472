// Components
import { Stack } from "react-bootstrap"
import FAQAccordion from "./FAQAccordion"
import Divider from "../UI/Divider"

const FAQ = ({ faqRef }) => {
    return (
        <Stack className='faq' ref={faqRef}>
            <h2 className='faqHeader'>
                Frequently Asked<br></br>
                Questions
            </h2>
            <Divider/>
            <FAQAccordion
                eventKey="0"
                faqNumber="01"
                faqQuestion="Where does Portal source its videos from?"
                faqBody={
                    <p>
                    All creators are vetted professionals who we partner with from YouTube, TikTok, and original
                    content sources. Our verification process involves a thorough review of each creator's
                    professional background, expertise, and industry credentials. We work closely with creators to
                    ensure they provide valuable perspectives and advice to our users, and we edit the content
                    before uploading to ensure that all information is verifiable, useful, and engaging. We believe
                    that this verification process is essential to maintaining the quality and trustworthiness of our
                    content, and to ensuring that our users can make informed decisions about their careers based
                    on reliable information from credible sources.
                    </p>
                }
            />
            <Divider/>
            <FAQAccordion
                eventKey="1"
                faqNumber="02"
                faqQuestion="How many videos does Portal have?"
                faqBody={
                    <p>
                    Portal hosts 1500+ videos on the platform from 150+ careers. Every week, fifty videos or more
                    are added to Portal. The ultimate goal is to host several day-in-the-life videos, salary videos at
                    several points on the spectrum, positive and negative testimonials videos, recruitment tips
                    videos, and other useful information in every career category.
                    </p>
                }
            />
            <Divider/>
            <FAQAccordion
                eventKey="2"
                faqNumber="03"
                faqQuestion="Can I make videos for this app?"
                faqBody={
                    <p>
                    If you are interested in helping the next generation of professionals navigate career options, and
                    you have the experience and expertise to make a meaningful impact, we encourage you to
                    become a creator on Portal today. As a creator on Portal, you will have the opportunity to share
                    your knowledge and experiences with a diverse audience of students and young professionals.
                    Our students have consistently expressed the impact the videos have made on their career
                    journey, and we love sharing their feedback with our creators. Click "Contact Us" now to begin
                    the process!
                    </p>
                }
            />
            <Divider/>
            <FAQAccordion
                eventKey="3"
                faqNumber="04"
                faqQuestion="Are the Creators on Portal verified?"
                faqBody={
                    <p>
                    To ensure the integrity and credibility of our platform, all creators on Portal are verified before
                    they can share their insights and experiences with our community. Our verification process
                    involves a thorough review of each creator's professional background, expertise, and industry
                    credentials. We work closely with creators to ensure they provide valuable perspectives and
                    advice to our users. When you watch a video on Portal, you can trust that the creator has been
                    vetted and approved by our team. We believe that this verification process is essential to
                    maintaining the quality and trustworthiness of our content, and to ensuring that our users can
                    make informed decisions about their careers based on reliable information from credible
                    sources
                    </p>
                }
            />
            <Divider/>
            <FAQAccordion
                eventKey="4"
                faqNumber="05"
                faqQuestion="How do I get access?"
                faqBody={
                    <p>
                    To begin the process of accessing Portal, click "Try Portal" above.
                    </p>
                }
            />
            <Divider/>
        </Stack>
    )
}

export default FAQ