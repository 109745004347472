import { Stack } from "react-bootstrap";
const Divider = () => {
return (
    <hr style={{ borderTop: '2px solid black'}}/>
)
}
const FeaturesTab = ({header, headerImg, desc, featureImg, featureImgClass, className}) => {
    return (
        <div className={className} style={{ marginLeft:'20px', marginRight:'20px' }}>
            <Divider/>
            <div className="featureTab" style={{marginBottom:10, display: 'flex'}}>
                <Stack direction="horizontal">
                <img src={headerImg} alt="PortalLogo" className="tabIcon" style={{marginRight:10}}></img>
                <div style={{marginTop:'auto', marginBottom:'auto',display:'block'}}>
                <h1 
                    className="rightHeader" 
                    style={{
                        textAlign:'left', 
                        marginTop: 'auto', 
                        marginBottom: 'auto'
                    }}
                >
                    {header} 
                </h1>
                </div>
                </Stack>
            </div>
            <div className="featureDesc">
                {desc}
            </div>
            <div style={{ display: 'flex' }}>
                <img 
                    src={featureImg} 
                    alt="PortalLogo" 
                    className={featureImgClass} 
                    style={{
                        marginRight: 'auto'
                    }}
                />
            </div>
        </div>
    );
}
export default FeaturesTab