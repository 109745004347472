import ReactGA from 'react-ga4';
const NavbarButton = ({ label, onClick }) => {
    const onNavBarEvent = (label) => {
        ReactGA.event({
          category: 'navbar',
          action: 'nav',
          label: `${label} - Nav Bar`
        }) 
    }
    return (
        <button 
            onClick={() => {
                onNavBarEvent()
                onClick()
            }}
        >     
            {label}
        </button>
    )
}

export default NavbarButton