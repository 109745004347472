const GetEarlyAccessButton = ({ fontSize, height, onClick, className='gea-btn' }) => {
    return (
        <button 
          className={className} 
          style={{
            fontSize: fontSize,
            height: height
          }} 
          onClick={ onClick }
        > 
          Get Early Access 
        </button>
    )
}

export default GetEarlyAccessButton