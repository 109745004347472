import './App.css';
import React, { useRef, useState } from 'react';

// Images
import darkLogo from './images/portal_dark.png';
import logo from './images/Portal_Logo_Main.png';
import darkRingLogo from './images/Portal_Ring_Logo.png';
import smallRingLogo from './images/Portal_Ring_Logo_Small.png';
import cross from './images/cross.png';
import checklist from './images/checklist.png';
import dottedCircle from './images/dotted_circle.png';
import message from './images/message.png';
// import feat0 from './images/Feature_0.webp';
// import feat1 from './images/Feature_1.webp';
// import feat2 from './images/Feature_2.webp';
import interests from './images/InterestSelection.png';
import question from './images/Q&A.png';
import feed from './images/HomeFeed.png';

// Hooks
import useWindowDimensions from './dimensions/windowDimensions';

// Components
import EarlyAccessForm from './components/EarlyAccessForm';
import ContactForm from './components/ContactForm';
import NavbarButton from './components/NavBar/NavbarButton';
import GetEarlyAccessButton from './components/UI/GetEarlyAccessButton';
import CareerCard from './components/CareerCard';
import FeaturesTab from './components/FeaturesTab';
import FeaturesCarousel from './components/FeaturesCarousel';
import Divider from './components/UI/Divider';
import CheckmarkBullet from './components/UI/CheckmarkBullet';
import FAQ from './components/FAQ/FAQ';
import CareerCenter from './components/CareerCenter/CareerCenter';

// Other Components
import ReactGA from 'react-ga4';

// Bootstrap (External)
import 'bootstrap/dist/css/bootstrap.min.css';
import { Stack, Offcanvas } from 'react-bootstrap';

function App() {
  // Navigation References
  const wwdRef = useRef(null)
  const featRef = useRef(null)
  const ccRef = useRef(null)
  const connectRef = useRef(null)
  const faqRef = useRef(null)

  // Showing Modals
  const [earlyAccessModalShow, setEarlyAccessModalShow] = useState(false);
  const [contactModalShow, setContactModalShow] = useState(false);
  const handleShowEarlyAccess = () => {
    ReactGA.event({
      category: 'button',
      action: 'get-early-access',
      label: 'pressed-gea-form'
    }) 
    setEarlyAccessModalShow(true);
  }
  const handleCloseEarlyAccess = () => {
    setEarlyAccessModalShow(false);
  }
  const handleShowContactForm = () => {
    ReactGA.event({
      category: 'button',
      action: 'get-contact-us',
      label: 'pressed-contact-form'
    }) 
    setContactModalShow(true);
  }
  const handleCloseContactForm = () => setContactModalShow(false);
  const scrollToWWD = () => {
    wwdRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToFeatures = () => {
    featRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToCC = () => {
    ccRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrolltoGetConnected = () => {
    connectRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  // Navigation OffCanvas
  const [showOffCanvas, setOffCanvas] = useState(false)
  const handleShowOffCanvas = () => {setOffCanvas(true)}
  const handleCloseOffCanvas = () => {setOffCanvas(false)}
  const { width, isMobile } = useWindowDimensions()
  
  // Styling
  const navBarHeaderStyle = {
    display: 'flex',
    width: '100%'
  }
  const navBarStyle = {
    display: 'block',
    margin: 'auto',
  }
  const horizCenterStyle = {
    marginLeft: 'auto', 
    marginRight: 'auto'
  }
  // Render
  return (
    <div className="App">
      {/* Forms */}
      <EarlyAccessForm
        show={earlyAccessModalShow}
        onHide={handleCloseEarlyAccess}
        submitprocess={handleCloseEarlyAccess}
      />
      <ContactForm
        show={contactModalShow}
        onHide={handleCloseContactForm}
        submitprocess={handleCloseContactForm}
      />
      {/* Navbar Header */}
      <Stack className='header' direction='horizontal'>
        { 
          isMobile ? 
          <>
            <img src={darkLogo} alt='logo'/>
            <div className='ms-auto headerMobileBtn'>
              <button onClick={handleShowOffCanvas}>☰</button>
            </div>
          </>
          :
          <div style={navBarHeaderStyle}>
            <div style={navBarStyle}>
              <img src={darkLogo} alt='logo'/>
            </div>
            <div style={navBarStyle}>
              <NavbarButton label={"What We Do"} onClick={scrollToWWD} />
              <NavbarButton label={"Features"} onClick={scrollToFeatures} />
              <NavbarButton label={"Career Center"} onClick={scrollToCC} />
              <NavbarButton label={"Get Connected"} onClick={scrolltoGetConnected} />
              <NavbarButton label={"FAQ"} onClick={scrollToFAQ} />
            </div>
          </div>
        }
      </Stack>
      {/* What We Do */}
      <Stack className='wwd' ref={wwdRef}>
        <>
          <h1>
            Never Feel Lost <br></br>
            About Career Choice
          </h1>
          <p 
            style={{
              marginLeft: width > 1025 ? '20%' : '5%', 
              marginRight: width > 1025 ? '20%' : '5%'
              // marginLeft: '5%', 
              // marginRight: '5%'
            }}
          >
            Students’ favorite career exploration tool made for this generation's { width > 508 && width < 566 && <br></br> } learning style.
          </p>
        </>
        <GetEarlyAccessButton fontSize={26} onClick={handleShowEarlyAccess} />
        <img src={isMobile ? smallRingLogo : darkRingLogo} alt='ring-logo'/>
      </Stack>
      {/* Offcanvas Navbar */}
      {
        isMobile &&
        <Offcanvas 
          className='vh-100 mobileNavHeader' 
          placement='top' 
          show={showOffCanvas} 
          onHide={handleCloseOffCanvas}
          scroll={true}
          responsive="md"
        >
          <Offcanvas.Header>
            <button 
              className='ms-auto' 
              onClick={handleCloseOffCanvas}>
              <img src={cross} alt='cross'/>
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className='navButtons'>
              <button onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  wwdRef.current.scrollIntoView({behavior: 'smooth'});
                }, 300)
              }}>
                What We Do
              </button>
              <button onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  featRef.current.scrollIntoView({behavior: 'smooth'});
                }, 300)
              }}>
                Features
              </button>
              <button onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  ccRef.current.scrollIntoView({behavior: 'smooth'});
                }, 300)
              }}>Career Centers</button>
              <button onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  faqRef.current.scrollIntoView({behavior: 'smooth'});
                }, 300)
              }}>
                FAQ
              </button>
              <button onClick={() => {
                handleCloseOffCanvas()
                setTimeout(() => {
                  connectRef.current.scrollIntoView({behavior: 'smooth'});
                }, 300)
              }}>Contact Us</button>
              <button 
                className='gea-nav-btn'
                onClick={() => {
                  handleCloseOffCanvas()
                  setTimeout(() => {
                    handleShowEarlyAccess()
                  }, 300)
                }}
              >Get Early Access</button>
            </Stack>
          </Offcanvas.Body>
        </Offcanvas>
      }
      {/* Features */}
      <Stack className='features' ref={featRef}>
        {
          isMobile ?
          <h2> 
            Portal makes career learning engaging and accessible.
          </h2>
          :
          <h2> 
            Portal makes career learning <br></br>
            engaging and accessible.
          </h2>
          
        }
        { isMobile && <FeaturesCarousel /> }
        { 
          !isMobile && 
          <div 
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: '1fr',
              gridColumnGap: 15,
              margin: 'auto'
//               display: grid;
// grid-template-columns: repeat(3, 1fr);
// grid-template-rows: 1fr;
// grid-column-gap: 15px;
// grid-row-gap: px;
// .div1 { grid-area: 1 / 1 / 2 / 2; }
// .div2 { grid-area: 1 / 2 / 2 / 3; }
// .div3 { grid-area: 1 / 3 / 2 / 4; }
            }}
          >
            <div style={{ gridArea: "1 / 1 / 2 / 2" }}>
              <FeaturesTab 
                header="Select your interests" 
                desc={
                  <p>
                    Choose what excites you and receive <br></br>
                    personalized career video content <br></br>  
                    tailored to your professional and <br></br>
                    academic interests.
                  </p>
                }
                headerImg={checklist}
                featureImg={interests}
                featureImgClass={"featureIcon"}
                className={"featureTab0"}
              />
            </div>
            
            <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
              <FeaturesTab 
                header="Enter the Portal" 
                desc={
                  <p>
                    See inside the careers you are or should <br></br>
                    be considering through short-form videos <br></br>
                    made by professionals on personalized, <br></br>
                    customizable content feeds.
                  </p>
                }
                headerImg={dottedCircle}
                featureImg={feed}
                featureImgClass={"featureIcon"}
                className={"featureTab1"}
              />
            </div>
            
            <div style={{ gridArea: "1 / 3 / 2 / 4" }}>
              <FeaturesTab 
                header="Learn More" 
                desc={
                  <p>
                    Explore the world of careers, ask <br></br>
                    professionals about their role in <br></br>
                    low-pressure forums, and envision <br></br>
                    the possibilities for your future.
                    {/* Connect with the video creators to ask <br></br> 
                    them questions about their role, make a  <br></br>
                    professional connection, or set up one day <br></br>
                    shadowing opportunities. */}
                  </p>
                }
                headerImg={message}
                featureImg={question}
                featureImgClass={"featureIcon"}
                className={"featureTab2"}
              />
            </div>
          </div>
        }
      </Stack>
      {/* Benefits */}
      <Stack 
        className='benefits' 
        style={{ flexDirection: isMobile ? 'column' : 'row' }}
      >
        {isMobile &&
          <div>
            <h2>
              Learn like internships <br></br> 
              through short-form videos.
            </h2>
            <p>
              See inside the day-to-day of professionals in your fields of interests, hear their opinions about the job, learn about industry trends, and more in short-form video format.
            </p>
           <GetEarlyAccessButton onClick={handleShowEarlyAccess}/>
          </div>
        }
        <div style={horizCenterStyle}>
          { !isMobile && 
            <div style={{ width: width * 0.4 }}>
              <h2>
                Learn like internships <br></br>
                through short-form videos.
              </h2>
              <p>
                See inside the day-to-day of professionals
                in your fields of interests, hear their 
                opinions about the job, learn about industry trends, 
                and more in short-form video format.
              </p>
              <GetEarlyAccessButton 
                className='benefitBtn' 
                fontSize={25} 
                height={70} 
                onClick={handleShowEarlyAccess}
              />
            </div>
          }
        </div>
        <div 
          style={{
            marginLeft: 15,
            marginRight: 'auto',
            display:'block'
          }}
        >
          <CheckmarkBullet text="Witness typical days-in-the-life of your career interests" />
          <Divider/>
          <CheckmarkBullet text="Hear testimonials from professionals in those fields" />
          <Divider/>
          <CheckmarkBullet text="Learn the basic knowledge required" />
          <Divider/>
          <CheckmarkBullet text="Much more..." />
        </div>
      </Stack>
      <div 
        style={{
          backgroundColor: 'black', 
          display:'block', 
          marginLeft: 'auto', 
          marginRight:'auto', 
          marginTop:'6rem', 
          marginBottom:'8rem'
        }} 
        className='wwdVideoBackground'
      >
        <video
          src={ isMobile ? 'videos/Vertical_Captioned.mov' : 'videos/Horizontal_Captioned.mp4' }
          className='wwdVideo'
          controls={true}
        />
      </div>
      <Stack 
        className='careerCenter' 
        ref={ccRef} 
        style={{
          marginLeft: 'auto'
        }}
      >
        <CareerCenter contactUsClick={handleShowContactForm} />
      </Stack>
      <Stack 
        className='getConnected' 
        ref={connectRef} 
        direction={ isMobile ? 'vertical' : 'horizontal' }
        gap={20}
      >
        <CareerCard
          bottom={ isMobile ? 60 : 0 }
          header={ isMobile ? <h3>STUDENTS & JOB <br></br>SWITCHERS</h3> : <h3>STUDENTS & JOB SWITCHERS</h3> }
          body={ isMobile ? <h2>Begin your <br></br> Journey to a <br></br> Happy Career</h2> : <h2>Begin your Journey to <br></br>a Happy Career</h2>}
          btnLabel='Get Early Access'
          onClick={handleShowEarlyAccess}
          width={isMobile ? '' : width * 0.4}
          right={isMobile ? 0 : '5%'}
        />
        <CareerCard
          bottom={ isMobile ? 120 : 0 }
          header={ isMobile ? <h3>CREATORS & CAREER <br></br>CENTERS</h3> : <h3> CREATORS & CAREER CENTERS</h3> }
          body={ isMobile ? <h2>Interested in <br></br> what we're <br></br> doing?</h2> : <h2>Interested in what <br></br> we're doing?</h2> }
          btnLabel='Contact Us'
          onClick={handleShowContactForm}
          width={ isMobile ? '' : width * 0.4 }
        />
      </Stack>
      {/* FAQ */}
      <FAQ faqRef={faqRef} />
      {/* Footer */}
      <Stack className='footer' style={{marginLeft:25, marginRight:25}}>
        <img 
          src={logo} 
          alt='logo' 
          width='240px'
          style={{paddingTop:'40px', paddingBottom:'20px'}}
        />
        <h3>CONTENTS</h3>
        <button onClick={scrollToWWD}>What We Do</button>
        <button onClick={scrollToFeatures}>Features</button>
        <button onClick={scrollToCC}>Career Centers</button>
        <button onClick={scrollToFAQ}>FAQ</button>
        <button onClick={handleShowContactForm}>Contact Us</button>
        <button onClick={handleShowEarlyAccess} style={{ fontWeight:800 }}>
          Get Early Access
        </button>
        <Divider/>
        <p>
          © 2023. All Rights Reserved. 
        </p>
      </Stack>
    </div>
  );
}

export default App;