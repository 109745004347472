import check from '../../images/check.png';
const CheckmarkBullet = ({ text }) => {
    const containerStyle = {
        display: 'flex'
    }
    const textStyle = {
        marginLeft: 10, 
        marginTop: 'auto', 
        marginBottom: 'auto'
    }
    return (
        <div style={containerStyle}>
            <img className='listBullet' src={check} alt='check'/>
            <span className='list' style={textStyle}>
                {text}
            </span>
        </div>
    )
}

export default CheckmarkBullet