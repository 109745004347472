import { Accordion } from "react-bootstrap"
const FAQAccordion = (props) => {
    const eventKey = props.eventKey
    const faqNumber = props.faqNumber
    const faqQuestion = props.faqQuestion
    const faqBody = props.faqBody
    return (
        <Accordion>
            <Accordion.Item eventKey={eventKey}>
              <Accordion.Header>
                <span className='faqNumber'>
                    {faqNumber}
                </span>
                <span className='faqQuestion'>
                    {faqQuestion}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {faqBody}
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
export default FAQAccordion